<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <b-form-group :label="$t('course')" class="mb-1">
                        <ValidationProvider name="course_id" :rules="formData.pool_id?'':'required'"
                                            v-slot="{ errors }">
                            <course-auto-complete v-model="formData.course_id"
                                                  :validate-error="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>

                    <b-form-group :label="$t('pool')">
                        <ValidationProvider name="pool_id" :rules="formData.course_id?'':'required'"
                                            v-slot="{ errors }">
                            <elective-pool-selectbox
                                v-model="formData.pool_id"
                            ></elective-pool-selectbox>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="mt-2" :label="$t('program_outcome_definition_text')">
                        <ValidationProvider name="program_outcome_id" v-slot="{ errors }">
                            <ProgramOutcomeDefinitionSelectbox
                                v-model="formData.program_outcome_id"
                                :validate-error="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>

                    <b-form-group :label="$t('value')">
                        <ValidationProvider name="rank" rules="required" v-slot="{ valid, errors }">
                            <b-form-input type="number" v-model="formData.value">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>

            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="storeForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import ProgramOutcomeDefinitionSelectbox from "@/components/interactive-fields/ProgramOutcomeDefinitionSelectbox.vue";
import ElectivePoolSelectbox from "@/components/interactive-fields/ElectivePoolSelectbox.vue";
import CourseProgramOutcomeServices from "@/services/CourseProgramOutcomeServices";

export default {
    components: {
        ElectivePoolSelectbox,
        ProgramOutcomeDefinitionSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            formData: {
                course_id: null,
                program_outcome_id:null

            },
        }
    },
    methods: {
        async storeForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                CourseProgramOutcomeServices.store(this.formData)
                    .then(response => {
                        this.$emit('createFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>
